
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.authentication = window.Smartly.locales.modules.en.back_royal.authentication || {};
window.Smartly.locales.modules.en.back_royal.authentication.login_register_footer = window.Smartly.locales.modules.en.back_royal.authentication.login_register_footer || {};
window.Smartly.locales.modules.en.back_royal.authentication.login_register_footer = {...window.Smartly.locales.modules.en.back_royal.authentication.login_register_footer, ...{
    "get_help": "Get help or contact us in <a ng-click=\"loadFaqUrl('/help', '_blank')\" name=\"support\">{{brandName}} Support.</a>",
    "simply_signout": "Or simply <a ng-click=\"signOut()\" name=\"sign-out\">sign out.</a>"
}
}
    
import angularModule from 'Authentication/angularModule/scripts/authentication_module';
import { setupBrandNameProperties } from 'AppBranding';
import template from 'Authentication/angularModule/views/confirm_profile_info_form.html';
import signupFormFieldTemplate from 'Authentication/angularModule//views/sign_up_form_field.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { identifyUser } from 'TinyEventLogger';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('confirmProfileInfoForm', [
    '$injector',

    function factory($injector) {
        const ngDeviseTokenAuthClient = $injector.get('ngDeviseTokenAuthClient');
        const $rootScope = $injector.get('$rootScope');
        const safeApply = $injector.get('safeApply');
        const ValidationResponder = $injector.get('ValidationResponder');
        const TranslationHelper = $injector.get('TranslationHelper');
        const AuthFormHelperMixin = $injector.get('AuthFormHelperMixin');
        const ClientStorage = $injector.get('ClientStorage');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                scope.signupFormFieldTemplate = signupFormFieldTemplate;
                const translationHelper = new TranslationHelper('authentication.confirm_profile_info_form');
                AuthFormHelperMixin.onLink(scope);
                setupBrandNameProperties($injector, scope);

                scope.includedFields = [
                    { type: 'text', name: 'name', placeholder: 'name_short' },
                    { type: 'email', name: 'email' },
                ];

                scope.emailPlaceholder = translationHelper.get('email');

                scope.form = {};
                scope.form_errors = {};

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.$watch('currentUser', currentUser => {
                    scope.form = {};
                    scope.form_errors = {};

                    if (!currentUser) {
                        return;
                    }

                    let provider = currentUser.provider;

                    if (['apple_quantic', 'apple_smartly'].includes(provider)) {
                        provider = 'apple';
                    }

                    if (_.includes(['google_oauth2', 'facebook', 'apple'], provider)) {
                        scope.providerName = translationHelper.get(provider);
                    }

                    scope.form.email = $rootScope.currentUser.email;
                    scope.form.name = $rootScope.currentUser.name;
                });

                // If OAuth provider user entered email has already been taken, show the `password` field to let user to input
                // the password for the entered email to link to the email account.
                scope.linkOAuthUserToExistingUser = () => {
                    if ($rootScope.currentUser.has_user_identity) {
                        scope.form.is_oauth_linking = true;
                        scope.includedFields.push({
                            type: 'password',
                            name: 'password',
                        });
                    }
                };

                // If the email changed, remove the `password` field and clear the error message for the OAuth user.
                scope.$watch('form.email', () => {
                    if ($rootScope.currentUser?.has_user_identity && scope.form.is_oauth_linking) {
                        delete scope.form.is_oauth_linking;
                        scope.form_errors.general = undefined;
                        const passwordItemIndex = scope.includedFields.findIndex(x => x.name === 'password');

                        if (passwordItemIndex > -1) {
                            scope.includedFields.splice(passwordItemIndex, 1);
                        }
                    }
                });

                // If the password changed, clear the error message.
                scope.$watch('form.password', () => {
                    if ($rootScope.currentUser?.has_user_identity && scope.form.is_oauth_linking) {
                        scope.form_errors.general = undefined;
                    }
                });

                scope.submitProfileConfirmation = () => {
                    scope.preventSubmit = true;
                    scope.form_errors = {};

                    // Keep sign in form simple and let users put in a single name field.
                    // Store it in name- rest of app will be resiliant to empty last name
                    scope.form.confirmed_profile_info = true;

                    // persist any changes to the user here, like we would in settings, check for errors, etc.
                    ngDeviseTokenAuthClient
                        .updateAccount(scope.form)
                        .then(
                            () => {
                                if (scope.form.is_oauth_linking) {
                                    // sign out current OAuth user firstly,
                                    // then login with the email and password which provided by the OAuth user
                                    ngDeviseTokenAuthClient
                                        .signOut()
                                        .then(() => {
                                            scope.form.provider = 'email';
                                            delete scope.form.confirmed_profile_info;
                                            delete scope.form.is_oauth_linking;
                                            return ngDeviseTokenAuthClient.submitLogin(scope.form);
                                        })
                                        .then(() => {
                                            // clear last_visited_route to prevent re-directing to the current URL in Cordova
                                            ClientStorage.removeItem('last_visited_route');
                                            // move on to next page
                                            ValidationResponder.forwardToTargetUrl();
                                        })
                                        .catch(() => {
                                            // If failed, redirect to /sign-in page
                                            ValidationResponder.setHref('/sign-in');
                                        });
                                } else {
                                    // see https://trello.com/c/xa4zY5EU/260-0-5-iguana-provisional-changes
                                    // for maybe a nicer way of handling this
                                    $rootScope.currentUser.email = scope.form.email;
                                    $rootScope.currentUser.name = scope.form.name;
                                    $rootScope.currentUser.school = scope.form.school;
                                    $rootScope.currentUser.confirmed_profile_info = scope.form.confirmed_profile_info;

                                    // Since the career profile is not reloaded after this step, we need to update
                                    // the name here, otherwise the Basic Info page of the application (where we're
                                    // redirected after this step), will show the previously set name for the user.
                                    $rootScope.currentUser.career_profile.name = scope.form.name;

                                    // re-identify
                                    identifyUser($rootScope.currentUser);

                                    // clear last_visited_route to prevent re-directing to the current URL in Cordova
                                    ClientStorage.removeItem('last_visited_route');

                                    // move on to next page
                                    ValidationResponder.forwardToTargetUrl();
                                }
                            },
                            response => {
                                scope.preventSubmit = false;
                                scope.form.confirmed_profile_info = false;

                                // Copy-pasted and adapted from institution_register_dir.js
                                if (response && response.data && response.data.errors) {
                                    if (response.data.errors.name) {
                                        scope.form_errors.name = `Name ${response.data.errors.name}`;
                                    }
                                    if (response.data.errors.email) {
                                        scope.form_errors.email = response.data.errors.full_messages[0];
                                        scope.linkOAuthUserToExistingUser();
                                    }

                                    // Show the password/user validation message when OAuth users try to link to an existing email account
                                    // (user not found, invalid login credentials, etc.)
                                    if (Array.isArray(response.data.errors)) {
                                        scope.form_errors.general = response.data.errors[0];
                                    }
                                } else {
                                    scope.form_errors.general = translationHelper.get('a_server_error_occurred', {
                                        brandName: scope.brandNameShort,
                                    });
                                }
                            },
                        )
                        .finally(() => {
                            safeApply($rootScope);
                        });
                };
            },
        };
    },
]);

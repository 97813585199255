
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.authentication = window.Smartly.locales.modules.en.back_royal.authentication || {};
window.Smartly.locales.modules.en.back_royal.authentication.forgot_password = window.Smartly.locales.modules.en.back_royal.authentication.forgot_password || {};
window.Smartly.locales.modules.en.back_royal.authentication.forgot_password = {...window.Smartly.locales.modules.en.back_royal.authentication.forgot_password, ...{
    "check_your_email": "Check your email",
    "enter_your_email": "Enter your email address and we'll send you instructions to reset your password.",
    "forgot_your_password": "Forgot your password?",
    "reset_my_password": "Reset my password",
    "return_to_login": "Return to <a ng-click=\"gotoSignIn()\" >Login</a>",
    "recover_letter": "{{brandName}} Recover Letter",
    "we_sent_you_an_email": "We sent you an email with instructions for resetting your password.",
    "your_email": "Your email",
    "unable_to_process_your_request": "Unable to process your request. Please make sure your email address is correct."
}
}
    

        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.authentication = window.Smartly.locales.modules.en.back_royal.authentication || {};
window.Smartly.locales.modules.en.back_royal.authentication.confirm_profile_info_form = window.Smartly.locales.modules.en.back_royal.authentication.confirm_profile_info_form || {};
window.Smartly.locales.modules.en.back_royal.authentication.confirm_profile_info_form = {...window.Smartly.locales.modules.en.back_royal.authentication.confirm_profile_info_form, ...{
    "a_server_error_occurred": "A server error occurred. {{brandName}} engineers have been notified",
    "confirm_the_following": "Confirm the following information",
    "email": "Email",
    "facebook": "Facebook",
    "google_oauth2": "Google",
    "apple": "Apple",
    "next": "Next",
    "next_step": "Next Step",
    "school_optional": "School (Optional)",
    "you_are_signing_up_using_x": "You're signing up using {{providerName}}"
}
}
    

        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.authentication = window.Smartly.locales.modules.en.back_royal.authentication || {};
window.Smartly.locales.modules.en.back_royal.authentication.sign_up_sidebars = window.Smartly.locales.modules.en.back_royal.authentication.sign_up_sidebars || {};
window.Smartly.locales.modules.en.back_royal.authentication.sign_up_sidebars = {...window.Smartly.locales.modules.en.back_royal.authentication.sign_up_sidebars, ...{
    "demo_access": "Experience our unique <strong>7-minute</strong> lessons.",
    "demo_fastest": "The <strong>fastest</strong> and most <strong>engaging</strong> learning experience available.",
    "demo_lessons": "<strong>Hundreds of lessons</strong> to advance your team's skills fast.",
    "horn_quote": "&ldquo;In my 10 years in the education world, {{brandName}} offers the <strong>best, most engaging learning experience</strong> of anything I've seen...incredible disruptive potential.&rdquo;",
    "horn_title": "Co-Founder, <br> Clayton Christensen Innovation Institute",
    "loukakos_quote": "&ldquo;The talent in {{brandName}} is great - a remarkable concentration of intelligence, diversity, and business savvy...love how easy it makes building a top performing team.&rdquo;",
    "loukakos_title": "Director at Facebook",
    "hundreds_of_lessons": "Hundreds of lessons to advance your business skills <strong>fast</strong>.",
    "key_terms": "Key terms glossary and reference.",
    "mba_in_hand": "Get started now. It’s fast and easy. After creating your account, you’ll finish your application and start learning right away with our full MBA curriculum. Good luck!",
    "seamless_access": "Seamless access on <strong>any device</strong>.",
    "mba_is_free": "The {{brandName}} MBA is Free",
    "unlimited_access": "<strong>Unlimited access</strong> to our full course library.",
    "employer_free": "It's free to sign up, browse, connect and interview!",
    "employer_free_beta": "It's free to sign up, browse, interview and hire while we're in beta!"
}
}
    
import angularModule from './authentication_module';
// NOTE: typically we have request interceptors turn themselves "on" when the associated module
// is configured. However, we don't want this interceptor to turn on when FrontRoyal.Authentication
// is configured (especially in specs). So, we add this interceptor to PrioritizedInterceptorsProvider
// in the FrontRoyal module's config block in FrontRoyal/angularModuleAngular/index.js

angularModule.factory('LogInAsInterceptor', [
    '$injector',

    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const $window = $injector.get('$window');
        const $q = $injector.get('$q');
        const ClientStorage = $injector.get('ClientStorage');

        return {
            // This request interceptor should execute before all other request interceptors,
            // and will abort a request when using log-in-as and the user on $rootScope is
            // not the user that is logged-in-as. This can happen when an admin has multiple
            // tabs open and uses log-in-as in one of them.
            request(config) {
                const logged_in_as_user_id = ClientStorage.getItem('logged_in_as_user_id');
                if (
                    $rootScope.currentUser &&
                    !!ClientStorage.getItem('logged_in_as') &&
                    !!logged_in_as_user_id &&
                    $rootScope.currentUser.id !== logged_in_as_user_id
                ) {
                    const requestTerminator = $q.defer();

                    // Setting the `timeout` property on a request's config object will allow
                    // us to safely abort a request when the promise is resolved.
                    //
                    // We do not want to error in this interceptor, because that results in
                    // a number of low-level exceptions that are difficult to handle. Aborting
                    // a request via `timeout` will make the client behave as if the user if
                    // offline. The requests will initially return nothing, but will be queued
                    // and then retried when the user leaves the log-in-as state.
                    //
                    // See also: https://docs.angularjs.org/api/ng/service/$http#usage
                    config.timeout = requestTerminator.promise;

                    requestTerminator.resolve(); // we need to resolve the promise immediately to abort the request immediately
                    $window.alert(
                        'ATTN: You have logged-in-as someone in another tab. You can dismiss this alert when you return to your previous state.',
                    );
                }

                return config;
            },
        };
    },
]);

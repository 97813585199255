import { find } from 'lodash/fp';
import { PROVIDER_OPTIONS } from './constants';

function selectedProviderOption(billingTransaction) {
    return find({
        value: billingTransaction.provider,
    })(PROVIDER_OPTIONS);
}

export default selectedProviderOption;

export default angular.module('AspectRatioEnforcer', []).factory('AspectRatioEnforcer', [
    'AClassAbove',
    '$window',
    (AClassAbove, $window) =>
        AClassAbove.subclass(() => ({
            // either define newTargetWidth and newTargetHeight in the initializer, or
            // call setMaxDimensions later
            /*
        example usages:
        var aspectRatioEnforcer = new AspectRatioEnforcer(
            img,
            targetWidth,
            targetHeight);

        var aspectRatioEnforcer = new AspectRatioEnforcer(loader)
                .setMaxDimensions(maxBlankImageWidth, maxBlankImageHeight);
        */
            initialize(img, newTargetWidth, newTargetHeight) {
                if (img && img.constructor === $window.Image) {
                    this.initialWidth = img.width;
                    this.initialHeight = img.height;
                    this.aspectRatio = img.width / img.height;
                } else if (img) {
                    this.initialWidth = $(img).width();
                    this.initialHeight = $(img).height();
                    // naturalWidth/naturalHeight not supported in IE<9
                    this.aspectRatio = img.naturalWidth / img.naturalHeight;
                }

                if (arguments.length > 2) {
                    this.calculate(newTargetWidth, newTargetHeight);
                } else if (angular.isDefined(this.aspectRatio)) {
                    this.calculate(null, null);
                }
            },

            setInitialDimensions(dimensions) {
                this.initialWidth = dimensions.width;
                this.initialHeight = dimensions.height;
                this.aspectRatio = dimensions.width / dimensions.height;
            },

            setMaxDimensions(maxWidth, maxHeight) {
                if (this.height > maxHeight) {
                    this.calculate(null, maxHeight);
                }

                if (this.width > maxWidth) {
                    this.calculate(maxWidth, null);
                }

                return this;
            },

            calculate(newTargetWidth, newTargetHeight) {
                let newWidth;
                let newHeight;

                const deltaX = Math.abs(newTargetWidth - this.initialWidth);
                const deltaY = Math.abs(newTargetHeight - this.initialHeight);

                if ((newTargetWidth && deltaX < deltaY) || (newTargetWidth && !newTargetHeight)) {
                    newWidth = newTargetWidth;
                    newHeight = newWidth / this.aspectRatio;
                } else if ((newTargetHeight && deltaX >= deltaY) || (newTargetHeight && !newTargetWidth)) {
                    newHeight = newTargetHeight;
                    newWidth = newHeight * this.aspectRatio;
                } else {
                    newHeight = this.initialHeight;
                    newWidth = this.initialWidth;
                }

                angular.extend(this, {
                    width: newWidth,
                    height: newHeight,
                });
            },
        })),
]);

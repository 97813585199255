import angularModule from 'Authentication/angularModule/scripts/authentication_module';

angularModule.factory('blockAuthenticatedAccess', [
    '$injector',

    $injector => {
        const Capabilites = $injector.get('Capabilities');
        const obj = {};

        /*
                Right now, the only reason we block authenticated access
                is because cookies are disabled.  Possible there could
                be other reasons to do so at some point.  This could maybe
                even be used for some kinds of site downtime or something.
            */
        Object.defineProperty(obj, 'block', {
            get() {
                return !Capabilites.localStorage && !Capabilites.cookies;
            },
            configurable: true, // tests
        });

        Object.defineProperty(obj, 'errorMessage', {
            get() {
                if (this.block) {
                    return 'You must enable cookies to login to smart.ly';
                }
            },
            configurable: true, // tests
        });

        return obj;
    },
]);

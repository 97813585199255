import angularModule from 'Authentication/angularModule/scripts/authentication_module';
import Auid from 'Auid';
import moment from 'moment-timezone';
import { preSignupValues } from 'TinyEventLogger';

angularModule.factory('omniauth', [
    '$injector',
    $injector => {
        const ngDeviseTokenAuthClient = $injector.get('ngDeviseTokenAuthClient');
        const ConfigFactory = $injector.get('ConfigFactory');
        const $window = $injector.get('$window');
        const $location = $injector.get('$location');
        const Locale = $injector.get('Locale');
        const $q = $injector.get('$q');
        const ClientStorage = $injector.get('ClientStorage');
        const ClientConfig = $injector.get('ClientConfig');
        const clientConfig = ClientConfig.current;
        const $rootScope = $injector.get('$rootScope');
        const safeApply = $injector.get('safeApply');

        return {
            loginWithProvider(provider, signUpCode, programType) {
                return ConfigFactory.getConfig()
                    .then(config => {
                        /*
                        For clients that don't want to support the sp-initiated auth flow
                        we just forward to the idp-initiated flow
                        rather than going with the sp-initiated flow.

                        FIXME: this appears to be broken. See details in
                        docs/oauth_login.md#SAML (entry #3 in the list)
                    */
                        const idpUrl = config.forceIdpInitiatedLoginUrlFor(provider);
                        if (idpUrl) {
                            $window.location.href = idpUrl;

                            // At this point, we expect to leave the front-royal app, we return a
                            // promise that will never resolve in order to prevent the auth
                            // call from ever being complete
                            return $q(() => {});
                        }
                        const opts = {
                            params: {
                                sign_up_code: signUpCode,
                                pref_locale: Locale.defaultLocaleForSignUpCode(signUpCode),
                                timezone: moment.tz.guess(),
                                cloudflare_country_code: $window.cloudflareCountryCode,
                                phone: $window.preSignupForm?.phone,
                            },
                        };

                        // Apple does NOT allow localhost, which Cordova uses in the InAppBrowser flow.
                        // This is mostly applicable to Android, since iOS uses `window.cordova.plugins.SignInWithApple`.
                        // I think we would only need `apple_quantic` in Cordova, but I put both to be safe.
                        if (['apple_quantic', 'apple_smartly'].includes(provider) && window.CORDOVA) {
                            opts.auth_origin_url = `${window.ENDPOINT_ROOT}/sign-in`;
                        }

                        const id = Auid.get($rootScope.currentUser);
                        if (id) {
                            opts.params.id = id;
                        }

                        if (programType) {
                            opts.params.program_type = programType;
                        }

                        // pull skip_apply from the query params (if available) and pass up to the server
                        const skip_apply = $location.search() && $location.search().skip_apply;
                        if (skip_apply) {
                            opts.params.skip_apply = skip_apply;
                        }

                        // pull the user id stored from a referral link (if available) and pass up to the server
                        const referredById = ClientStorage.getItem('referredById');
                        if (referredById) {
                            opts.params.referred_by_id = referredById;
                        }

                        // append the client config since this will be accessed via a hard link resolution
                        // and will not be subject to angular interceptors
                        opts.params.fr_client = clientConfig.identifier;

                        // add the dynamic landing page multi-step pre-signup form to the params
                        // FIXME: See if this is still necessary
                        _.extend(opts.params, preSignupValues.allServer());

                        return ngDeviseTokenAuthClient.authenticate(provider, opts);
                    })
                    .finally(() => {
                        safeApply($rootScope);
                    });
            },
        };
    },
]);

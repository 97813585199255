
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.authentication = window.Smartly.locales.modules.en.back_royal.authentication || {};
window.Smartly.locales.modules.en.back_royal.authentication.auth_form_helper_mixin = window.Smartly.locales.modules.en.back_royal.authentication.auth_form_helper_mixin || {};
window.Smartly.locales.modules.en.back_royal.authentication.auth_form_helper_mixin = {...window.Smartly.locales.modules.en.back_royal.authentication.auth_form_helper_mixin, ...{
    "server_error": "Server Error",
    "error_account_deactivated": "Your account has been deactivated. Contact <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> if you wish to reactivate it.",
    "error_account_in_deletion_queue": "Your account is scheduled for deletion. Contact <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> for further information.",
    "please_install_wechat": "Please install WeChat"
}
}
    
import 'ng-toast';
import 'angular-cookie';
import 'angular-bind-html-compile';

import 'EventLogger/angularModule';
import 'Capabilities/angularModule';
import 'CaptchaHelper/angularModule';
import 'ClientStorage/angularModule';
import 'ErrorLogging/angularModule';
import 'Injector/angularModule';
import 'InstitutionalSubdomain/angularModule';
import 'PrioritizedInterceptors/angularModule';
import 'SafeApply/angularModule';
import 'Telephone/angularModule';
import 'Translation/angularModule';
import 'AngularHttpQueueAndRetry/angularModule';
import 'Careers/angularModule';
import 'EmailInput/angularModule';
import 'Navigation/angularModule';
import 'OnImageLoad/angularModule';
import 'ScrollHelper/angularModule';
import 'TimeoutHelpers/angularModule';
import 'Users/angularModule';
import 'Countries/angularModule';
import 'DeviseTokenAuthClient/angularModule';

export default angular
    .module('FrontRoyal.Authentication', [
        'angular-bind-html-compile',
        'timeoutHelpers',
        'scrollHelper',
        'Translation',
        'Capabilities',
        'EventLogger',
        'FrontRoyal.Careers',
        'FrontRoyal.Users',
        'FrontRoyal.Navigation',
        'FrontRoyal.EmailInput',
        'FrontRoyal.Countries',
        'ClientStorage',
        'DeviseTokenAuthClient',
        'ngToast',
        'onImageLoad',
        'prioritizedInterceptors',
        'HttpQueueAndRetry',
        'institutionalSubdomain',
        'FrontRoyal.ErrorLogService',
        'FrontRoyal.Telephone',
        'safeApply',
        'captchaHelper',
        'Injector',
    ])
    .config([
        'CapabilitiesProvider',
        CapabilitiesProvider => {
            const Capabilities = CapabilitiesProvider.$get();

            // Hack that lets us hard refresh to get back out of logged-in-as-user mode.
            // The auth cookie was swapped back just prior via home_controller#restore_prev_auth_cookie
            if (Capabilities.localStorage && window.localStorage.logged_in_as_user_id) {
                window.localStorage.removeItem('logged_in_as_user_id');
            }
        },
    ])
    .run([
        '$injector',
        $injector => {
            const EventLogger = $injector.get('EventLogger');
            EventLogger.setLabelProperty('sign_up_code', ['viewed_join_form', 'viewed_join_form_step_2']);
            EventLogger.setLabelProperty('provider', ['auth:login_with_provider', 'auth:submit_registration']);
        },
    ]);

import angularModule from 'Authentication/angularModule/scripts/authentication_module';
import Auid from 'Auid';

angularModule.factory('AuthenticationInterceptor', [
    '$injector',

    $injector => {
        const $window = $injector.get('$window');
        const $rootScope = $injector.get('$rootScope');

        return {
            request(config) {
                config.headers = config.headers || {};
                config.headers.auid = Auid.ensure($rootScope.currentUser);
                config.headers['scorm-token'] = $window.scormToken;
                return config;
            },
        };
    },
]);

angularModule.config([
    '$injector',
    $injector => {
        // this needs to run prior to the $auth interceptor, such that the custom auth headers are set
        const PrioritizedInterceptorsProvider = $injector.get('PrioritizedInterceptorsProvider');
        PrioritizedInterceptorsProvider.addInterceptor(-1, 'AuthenticationInterceptor');
    },
]);

import angularModule from 'Authentication/angularModule/scripts/authentication_module';
import { setupBrandNameProperties, setupBrandScopeProperties } from 'AppBranding';
import template from 'Authentication/angularModule/views/logged_out_page.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('loggedOutPage', [
    '$injector',

    function factory($injector) {
        const institutionalSubdomain = $injector.get('institutionalSubdomain');
        const omniauth = $injector.get('omniauth');
        const $location = $injector.get('$location');
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                const config = ConfigFactory.getSync();
                setupBrandNameProperties($injector, scope, { config });
                setupBrandScopeProperties($injector, scope, ['loggedOutPageLogo'], { config });

                scope.signIn = () => {
                    // We should never be on this page without an institutionalSubdomain,
                    // but just to be sure, support both possibilities.
                    if (institutionalSubdomain.id) {
                        omniauth.loginWithProvider(institutionalSubdomain.id);
                    } else {
                        $location.path('/sign-in');
                    }
                };
            },
        };
    },
]);

import { filter } from 'lodash/fp';

export const PROVIDER_PAYPAL = 'Paypal - Q4XWQ';
export const PROVIDER_STRIPE = 'stripe - smart.ly';
export const PROVIDER_SVB = 'SVB - 38322';
export const PROVIDER_UNKNOWN = 'Unknown - old payment';
export const TRANSACTION_TYPE_PAYMENT = 'payment';
export const TRANSACTION_TYPE_SURCHARGE = 'surcharge';
export const TRANSACTION_TYPE_CREDIT = 'credit';

export const TYPES_WITH_POSITIVE_AMOUNTS = [TRANSACTION_TYPE_PAYMENT, TRANSACTION_TYPE_CREDIT];
export const TYPES_WITH_NEGATIVE_AMOUNTS = [TRANSACTION_TYPE_SURCHARGE];
export const TRANSACTION_TYPES = TYPES_WITH_POSITIVE_AMOUNTS + TYPES_WITH_NEGATIVE_AMOUNTS;

export const TRANSACTION_TYPE_OPTIONS = [
    {
        label: 'Payment',
        value: TRANSACTION_TYPE_PAYMENT,
    },
    {
        label: 'Surcharge',
        value: TRANSACTION_TYPE_SURCHARGE,
    },
    {
        label: 'Credit',
        value: TRANSACTION_TYPE_CREDIT,
    },
];

export const PROVIDER_OPTIONS = [
    {
        name: PROVIDER_STRIPE,
        value: PROVIDER_STRIPE,
        selectableByAdmins: false,
        allowManualRefunds: true,
    },
    {
        name: PROVIDER_UNKNOWN,
        value: PROVIDER_UNKNOWN,
        selectableByAdmins: false,
        allowManualRefunds: false,
    },
    {
        name: PROVIDER_SVB,
        value: PROVIDER_SVB,
        selectableByAdmins: true,
        allowManualRefunds: true,
    },
    {
        name: PROVIDER_PAYPAL,
        value: PROVIDER_PAYPAL,
        selectableByAdmins: true,
        allowManualRefunds: true,
    },
];

export const PROVIDERS_SELECTABLE_BY_ADMINS = filter({
    selectableByAdmins: true,
})(PROVIDER_OPTIONS);

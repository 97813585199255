import angularModule from 'Authentication/angularModule/scripts/authentication_module';
import { setupBrandNameProperties } from 'AppBranding';
import template from 'Authentication/angularModule/views/login_register_footer.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('loginRegisterFooter', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                urlPrefix: '@',
            },
            link(scope) {
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                NavigationHelperMixin.onLink(scope);
                setupBrandNameProperties($injector, scope, { config: ConfigFactory.getSync() });

                // This is tested in CompleteRegistration, which is probably just due to code
                // moving around over time
                scope.signOut = () => {
                    // don't bother injecting unless we're signing out
                    $injector.get('SignOutHelper').signOut();
                };
            },
        };
    },
]);

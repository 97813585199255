
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.authentication = window.Smartly.locales.modules.en.back_royal.authentication || {};
window.Smartly.locales.modules.en.back_royal.authentication.sign_up_form = window.Smartly.locales.modules.en.back_royal.authentication.sign_up_form || {};
window.Smartly.locales.modules.en.back_royal.authentication.sign_up_form = {...window.Smartly.locales.modules.en.back_royal.authentication.sign_up_form, ...{
    "company": "Company",
    "country": "Country",
    "email": "Email Address",
    "select": "Select",
    "experience_a_demo": "Experience a {{brandName}} lesson for yourself today",
    "job_title": "Job Title",
    "join": "Create Account",
    "join_demo": "Start Demo",
    "name": "Full Name",
    "name_short": "Name",
    "or_enter_info_below": "Or enter the information below",
    "password": "Password",
    "location": "Location",
    "password_min_length": "Password must be at least 8 characters long",
    "phone": "Phone Number",
    "school_optional": "School (Optional)",
    "server_error": "A server error occurred. {{brandName}} engineers have been notified",
    "sign_up_its_free": "Sign Up - It's Free",
    "sign_up_for_free_using": "Sign up for free using",
    "sign_up_using_phone_number": "Sign up with your phone number",
    "sign_up_using_emu_email": "Sign up with your EMU email address",
    "sign_up_using_georgetown_email": "Sign up with your Georgetown email address",
    "sign_up_using_nyu_email": "Sign up with your Stern email address",
    "sign_up_using_inframark_email": "Sign up with your Inframark email address",
    "miyamiya_title_message": "Sign up for <strong>Miya Miya</strong>",
    "valar_title_message": "Let&rsquo;s Get You Signed Up. <br /> It&rsquo;s Free!",
    "company_employee_count": "How many employees are in your company?",
    "company_year": "What year was your company founded?",
    "company_annual_revenue": "What is your company's annual revenue?",
    "apply": "Apply",
    "no": "No",
    "yes": "Yes",
    "professional_organization": "Company",
    "default_placeholder": "{{}}",
    "privacy_notice": "We value your privacy. We will never sell your data, and we strive to keep your information safe and secure. After registration, we may occasionally email you about important application deadlines and announcements. At any time, you may adjust your notification preferences or request removal of your account. For further details, see our <a href=\"https://{{domain}}/privacy\">privacy policy</a>.",
    "sign_up_with_google": "Sign Up with Google",
    "sign_up_with_facebook": "Sign Up with Facebook",
    "sign_up_with_apple": "Sign up with Apple",
    "sign_up_with_wechat": "Sign up with WeChat",
    "or": "Or",
    "name_with_star": "Name *",
    "name_placeholder": "Enter full name",
    "email_placeholder": "email@provider.com",
    "name_error_required": "Please enter your name",
    "email_address_with_star": "Email address *",
    "email_address_error_required": "Please use your email address",
    "password_with_star": "Password *",
    "password_error_required": "Please enter your password",
    "sign_up": "Sign Up",
    "submit": "Submit",
    "already_have_account": "Already have an account?",
    "sign_up_for_free": "Sign up for free to...",
    "log_in": "Log In",
    "get_signed_up": "Let's get you signed up. It's free!",
    "apply_mba_degree_programs": "Apply to the MBA or Executive MBA degree programs",
    "apply_to_emba": "Apply to the Executive MBA",
    "immediate_access": "Immediately Access 40+ Free Lessons",
    "preview_global_alumni_network": "Preview the Global Alumni Network",
    "access_course_library": "Access our free course library",
    "location_error_required": "Please select a location",
    "sending": "Sending",
    "please_enter_your_blank": "Please enter your {{name}}"
}
}
    
import { TRANSACTION_TYPE_PAYMENT, PROVIDER_OPTIONS, PROVIDERS_SELECTABLE_BY_ADMINS } from './constants';
import hasUnselectableProvider from './hasUnselectableProvider';
import selectedProviderOption from './selectedProviderOption';

const singleOptionLists = {};
PROVIDER_OPTIONS.forEach(opt => {
    singleOptionLists[opt.value] = [opt];
});

export function providerOptionsForSelectInput(billingTransactionOrRefund) {
    if (hasUnselectableProvider(billingTransactionOrRefund)) {
        // When the user is not allowed to change the provider, we want to show
        // just one provider in the list.  In order to prevent infinite digests, we
        // have to return a stable array.
        return singleOptionLists[selectedProviderOption(billingTransactionOrRefund).value];
    }
    return PROVIDERS_SELECTABLE_BY_ADMINS;
}

export function isPayment(billingTransaction) {
    return billingTransaction.transaction_type === TRANSACTION_TYPE_PAYMENT;
}

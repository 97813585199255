import { PROVIDER_PAYPAL, PROVIDER_STRIPE } from './constants';

function externalLink(provider, providerTransactionId, stripeLiveMode) {
    if (!providerTransactionId) {
        return null;
    }

    if (provider === PROVIDER_PAYPAL) {
        return `https://www.paypal.com/myaccount/transactions/details/${providerTransactionId}`;
    }
    if (provider === PROVIDER_STRIPE) {
        const envPart = stripeLiveMode ? '' : 'test/';
        return `https://dashboard.stripe.com/${envPart}payments/${providerTransactionId}`;
    }
    return null;
}

export default externalLink;

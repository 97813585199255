import angularModule from 'Authentication/angularModule/scripts/authentication_module';
import Auid from 'Auid';
import { beforeUnsettingCurrentUser, unsetCurrentUser } from 'Authentication';
import { Brand } from 'AppBranding';
import { targetBrand } from 'AppBranding';
import { InstitutionId } from 'Institutions';
import {
    QUANTIC_DOMAIN,
    QUANTIC_DOMAIN_STAGING,
    QUANTIC_CHINA_DOMAIN,
    QUANTIC_CHINA_DOMAIN_STAGING,
    VALAR_DOMAIN,
    VALAR_DOMAIN_STAGING,
} from 'PedagoDomainConstants';

angularModule.factory('SignOutHelper', [
    '$injector',

    function factory($injector) {
        const ngDeviseTokenAuthClient = $injector.get('ngDeviseTokenAuthClient');
        const $window = $injector.get('$window');
        const $location = $injector.get('$location');
        const EventLogger = $injector.get('EventLogger');
        const $rootScope = $injector.get('$rootScope');
        const institutionalSubdomain = $injector.get('institutionalSubdomain');
        const ClientStorage = $injector.get('ClientStorage');
        const TransientClientStorage = $injector.get('TransientClientStorage');
        const HttpQueue = $injector.get('HttpQueue');
        const safeApply = $injector.get('safeApply');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            signOut() {
                EventLogger.log('user:logout');

                // attempt to disconnect auth plugins
                if ($window.CORDOVA) {
                    // facebook plugin
                    try {
                        window.facebookConnectPlugin.logout(
                            () => {},
                            () => {},
                        );
                        // eslint-disable-next-line no-empty
                    } catch (ex) {}

                    // googleplus plugin
                    try {
                        window.plugins.googleplus.disconnect(() => {});
                        // eslint-disable-next-line no-empty
                    } catch (ex) {}
                }

                // Note: The signout process is a bit hacky. We ran into issues with stale session and auth
                // cookies sometimes persisting after signout, see https://trello.com/c/qIW0seFA. For some reason the
                // issue seemed to present itself when switching to the app subdomain. But in theory, the issue seems to
                // make sense because we don't queue our auth requests, and event requests fire off when signing out,
                // so that seems like it could present a race condition problem with the concurrent requests.
                // To remediate the issue, before signout out we turn off event logging, then flush all events (in beforeUnsettingCurrentUser),
                // reset the queue to cancel any queued in-flight requests (technically there are some shouldQueue: false requests,
                // but it's not very practical that those would be in-flight for a learner signing out), do the auth signout request,
                // clean up user client state, then ultimately redirect the user.

                // Show a spinner and disable event logging before attempting signout
                $rootScope.signingOut = true;
                EventLogger.disabled = true;

                // beforeUnsettingCurrentUser will flush any events logged before signOut was called
                this.beforeUnsettingCurrentUser()
                    .then(() => {
                        // Cancel any in-flight requests and delete any queued requests. This seems safe to do as the
                        // the user is logging out. If there's something we need to ensure is done before signout, it
                        // should be in beforeUnsettingCurrentUser.
                        HttpQueue.reset();

                        return ngDeviseTokenAuthClient.signOut();
                    })
                    .then(this.onSignoutSuccess)
                    .finally(() => {
                        // Flip back
                        $rootScope.signingOut = false;
                        EventLogger.disabled = false;

                        // beforeUnsettingCurrentUser return a native promise
                        safeApply($rootScope);
                    });
            },

            onSignoutSuccess() {
                const config = ConfigFactory.getSync();

                // the config isn't really needed in this instance
                // since we have a currentUser, but we have it, so...
                const branding = targetBrand($rootScope.currentUser, config);

                unsetCurrentUser($injector);

                ClientStorage.purgeEntriesFromLocalStorage(/ExamVerification/);
                ClientStorage.purgeEntriesFromLocalStorage(/completedStripeCheckoutSession/);

                [
                    'librarySearchProgress',
                    'librarySearchText',
                    'librarySearchTopic',
                    'toggleableCourseListLibrary',
                    'toggleableCourseListStudentDashboard',
                    'soundEnabled',
                    'amplitude_sessionId',
                    'amplitude_unsent',
                    'amplitude_lastEventId',
                    'amplitude_lastEventTime',
                    'last_visited_route',
                    'onboardingQuestions',
                    'sharedCareerProfiles',
                    'continueApplicationInMarketing',
                ].forEach(key => ClientStorage.removeItem(key));

                // We store various Reports JSON in TransientClientStorage - we want to clear
                // out everything here on sign out. See also Report.reportTypes.
                [
                    'ActiveUsersReport',
                    'UsersReport',
                    'TimeOnTaskReport',
                    'TimeOnTaskSingleUserReport',
                    'UserLessonProgressReport',
                    'PlayerLessonSessionsReport',
                    'EditorLessonSessionsReport',
                ].forEach(key => TransientClientStorage.removeItem(key));

                Auid.remove();

                // Users can sign into the app but be in an institution that's not for the app
                // they signed into. For users like this, the app is themed differently from the
                // default Quantic coral. For example, a Valar student may sign into the Quantic
                // app and be presented with the Valar UI, which is blue. However, when they sign
                // out of the app, the UI should revert back to the coral Quantic theme.
                AppHeaderViewModel.setBodyBackground();

                // If we send institutional users to the homepage, they
                // will just get automatically logged back in due to their
                // subdomain.
                if ($window.CORDOVA) {
                    $location.path($rootScope.postLogoutSignInPath);
                } else if (institutionalSubdomain.id) {
                    $location.path('/logged-out');
                } else if (!$window.RUNNING_IN_TEST_MODE) {
                    // In web, for Quantic and Valar staging/production we send you straight to the Gatsby root domain.
                    // But for other users (ex. Smartly) and the various local and testing scenarios we send you to the
                    // Rails root route, which has some special-handling logic.
                    if (config.appEnvType() === 'development') {
                        $window.location.replace(
                            branding === Brand.valar ? `/?institution_id=${InstitutionId.valar}` : '/',
                        );
                    } else if (config.isAlternativeStagingEnvironment()) {
                        $window.location.replace('/');
                    } else if (branding === Brand.valar) {
                        $window.location.replace(
                            config.appEnvType() === 'staging'
                                ? `https://${VALAR_DOMAIN_STAGING}`
                                : `https://${VALAR_DOMAIN}`,
                        );
                    } else if (branding === Brand.quantic && config.chinaRegionMode()) {
                        $window.location.replace(
                            config.appEnvType() === 'staging'
                                ? `https://${QUANTIC_CHINA_DOMAIN_STAGING}`
                                : `https://${QUANTIC_CHINA_DOMAIN}`,
                        );
                    } else if (branding === Brand.quantic) {
                        $window.location.replace(
                            config.appEnvType() === 'staging'
                                ? `https://${QUANTIC_DOMAIN_STAGING}`
                                : `https://${QUANTIC_DOMAIN}`,
                        );
                    } else {
                        $window.location.replace('/');
                    }
                }
            },

            // local method we can mock in specs
            beforeUnsettingCurrentUser: () => beforeUnsettingCurrentUser($injector),
        };
    },
]);


        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.authentication = window.Smartly.locales.modules.en.back_royal.authentication || {};
window.Smartly.locales.modules.en.back_royal.authentication.sign_in_form = window.Smartly.locales.modules.en.back_royal.authentication.sign_in_form || {};
window.Smartly.locales.modules.en.back_royal.authentication.sign_in_form = {...window.Smartly.locales.modules.en.back_royal.authentication.sign_in_form, ...{
    "email_address": "Email Address",
    "phone": "Phone Number",
    "facebook": "Sign in with Facebook",
    "forgot_your_password": "Forgot your password?",
    "google": "Sign in with Google",
    "login": "Login",
    "log_in": "Log In",
    "login_to_your_account": "Login to your account using",
    "or_enter_the_info": "Or enter the information below",
    "password": "Password",
    "apple": "Sign in with Apple",
    "wechat": "Sign in with Wechat",
    "login_apple": "Log In With Apple",
    "login_wechat": "Log In With WeChat",
    "login_facebook": "Log In With Facebook",
    "login_google": "Log In With Google",
    "or": "or",
    "enter_your_email": "Enter Your E-mail"
}
}
    
import * as Yup from 'yup';
import 'YupExtensions/nonNegative';
import { validationSchema as refundValidationSchema } from 'Refund';
import { TRANSACTION_TYPE_PAYMENT } from './constants';

const requiredIfPayment = Yup.string().when('transaction_type', {
    is: TRANSACTION_TYPE_PAYMENT,
    then: Yup.string().required(),
    otherwise: Yup.string().nullable().oneOf([null, ''], 'Null'),
});

const validationSchema = Yup.object().shape({
    transaction_type: Yup.string().required(),
    provider: requiredIfPayment,
    provider_transaction_id: requiredIfPayment,
    transaction_time: Yup.date().required(),
    amount: Yup.number().positive().required(),
    refunds: Yup.array()
        .of(refundValidationSchema)
        .test((refunds, { parent: { amount }, createError, path }) => {
            const amountRefunded = (refunds || []).reduce((sum, refund) => sum + refund.amount, 0);

            if (!amountRefunded) return true;

            if (amount && amount >= amountRefunded) return true;

            return createError({
                path,
                type: 'refundAmountValidation',
                message: 'Total refund amount must be less than or equal to the transaction amount',
            });
        }),
});

export default validationSchema;

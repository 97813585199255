import angularModule from 'Authentication/angularModule/scripts/authentication_module';
import template from 'Authentication/angularModule/views/sign_in_form.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { isSameSiteNoneCompatible } from 'should-send-same-site-none';
import { isAndroidDevice, isThirdPartyWebView } from 'userAgentHelper';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('signInForm', [
    '$injector',

    function factory($injector) {
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const AuthFormHelperMixin = $injector.get('AuthFormHelperMixin');
        const ValidationResponder = $injector.get('ValidationResponder');
        const JoinConfig = $injector.get('JoinConfig');
        const ConfigFactory = $injector.get('ConfigFactory');
        const safeDigest = $injector.get('safeDigest');
        const $translate = $injector.get('$translate');
        const $window = $injector.get('$window');
        const TranslationHelper = $injector.get('TranslationHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                showForgotPassword: '<',
                contained: '<',
                urlPrefix: '<?',
            },
            link(scope, elem) {
                // Get the language so we can add classes to oauth buttons
                scope.language = $translate.use(false);

                scope.isThirdPartyWebView = isThirdPartyWebView();
                // Show Apple as an Oauth provider in every browser compatible with the SameSite=None cookie attribute
                // unless we're in the Android app (we do show it in mobile web on Android).
                scope.showAppleProvider =
                    isSameSiteNoneCompatible(navigator.userAgent) && !($window.CORDOVA && isAndroidDevice());

                const translationHelper = new TranslationHelper('authentication.sign_in_form');
                scope.locales = {
                    wechat: translationHelper.get('wechat'),
                    apple: translationHelper.get('apple'),
                    google: translationHelper.get('google'),
                    facebook: translationHelper.get('facebook'),
                    or: translationHelper.get('or_enter_the_info'),
                    email: translationHelper.get('email_address'),
                    password: translationHelper.get('password'),
                    login: translationHelper.get('login'),
                    forgotPassword: translationHelper.get('forgot_your_password'),
                };

                // get signup code from config
                JoinConfig.getConfig(scope.urlPrefix).then(urlConfig => {
                    scope.urlConfig = urlConfig;
                    scope.showSocial = !urlConfig.disable_oauth;
                    scope.chinaRegionMode = ConfigFactory.getSync().chinaRegionMode();
                    scope.logoImg = ConfigFactory.getSync().isQuantic()
                        ? urlConfig.quantic_logo_img
                        : urlConfig.smartly_logo_img;

                    if (urlConfig.alternate_form_locales) {
                        scope.locales = {
                            ...scope.locales,
                            wechat: translationHelper.get('login_wechat'),
                            apple: translationHelper.get('login_apple'),
                            google: translationHelper.get('login_google'),
                            facebook: translationHelper.get('login_facebook'),
                            or: translationHelper.get('or'),
                            login: translationHelper.get('log_in'),
                        };
                    }

                    const stopListeningForLoginSuccess = scope.$on('validation-responder:login-success', () => {
                        const onConfirm = () => ValidationResponder.forwardToTargetUrl();
                        ValidationResponder.checkAndConfirmBrandRedirect(urlConfig, onConfirm);
                    });

                    scope.$on('$destroy', () => {
                        stopListeningForLoginSuccess();
                    });

                    // Triggering a digest here to fix https://trello.com/c/M3yhUuSN
                    safeDigest(scope);
                });

                AuthFormHelperMixin.onLink(
                    scope,
                    ValidationResponder.forwardToTargetUrl,
                    AuthFormHelperMixin.getCustomOmniauthErrorResponder(scope),
                );

                NavigationHelperMixin.onLink(scope);

                scope.showPassword = false;
                scope.form = {};

                scope.submit = () => {
                    // It is possible that we will need to show a modal after
                    // submitting login.  If the user still has an input focused
                    // at that point, the keyboard might push the modal up off of
                    // the screen.  By disabling each input, we make sure you can see
                    // the whole screen after submitting.  (See the call to getCurrentUser()
                    // in ValidationResponder for an example)
                    elem.find('input').each(function () {
                        $(this).trigger('blur');
                    });
                    scope.submitLogin(scope.form);
                };

                // overridden from AuthFormHelperMixin ... why are we doing this exactly?
                scope.formErrorClasses = () => {
                    if (scope.form_errors.general) {
                        return ['form-error', 'active'];
                    }
                    if (scope.form_errors.phone) {
                        return ['form-error', 'active'];
                    }
                    return ['form-error'];
                };

                scope.formValidationClasses = () => {
                    if (scope.form_errors.general) {
                        return ['form-control', 'ng-invalid'];
                    }
                    return ['form-control'];
                };

                scope.toggleShowPassword = evt => {
                    evt.preventDefault();
                    evt.stopImmediatePropagation();
                    scope.showPassword = !scope.showPassword;
                };
            },
        };
    },
]);

import { createFormFieldConversions, convertTimestamp } from 'FrontRoyalForm';
import { toForm as refundToForm, fromForm as refundFromForm } from 'Refund';
import { TYPES_WITH_POSITIVE_AMOUNTS } from './constants';

const [toForm, fromForm] = createFormFieldConversions({
    ...convertTimestamp('transaction_time'),
    amount: {
        fromForm: values => {
            if (values.amount && values.amount < 0) {
                throw new Error('Expecting positive input');
            }

            if (TYPES_WITH_POSITIVE_AMOUNTS.includes(values.transaction_type)) {
                return values.amount;
            }
            return -values.amount;
        },
        toForm: values => (values.amount ? Math.abs(values.amount) : null),
    },
    refunds: {
        async fromForm(values, { injector }) {
            return mapRefunds(refundFromForm, values.refunds, injector);
        },
        toForm(values, { injector }) {
            return mapRefunds(refundToForm, values.refunds, injector);
        },
    },
});

async function mapRefunds(meth, refunds, injector) {
    refunds = refunds || [];
    const result = [];
    for (let i = 0; i < refunds.length; i++) {
        const refundAttrs = refunds[i];

        // Doing this in sequence ensures that we keep the same order,
        // so I'm ignoring the warning here.
        // eslint-disable-next-line no-await-in-loop
        const refund = await meth(refundAttrs, { injector });
        result.push(refund);
    }
    return result;
}

export { toForm, fromForm };

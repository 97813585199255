import {
    QUANTIC_DOMAIN,
    QUANTIC_APP_DOMAIN,
    QUANTIC_CHINA_DOMAIN,
    QUANTIC_CHINA_APP_DOMAIN,
    QUANTIC_CHINA_DOMAIN_STAGING,
    QUANTIC_CHINA_APP_DOMAIN_STAGING,
    SMARTLY_APP_DOMAIN,
    QUANTIC_APP_DOMAIN_STAGING,
    SMARTLY_APP_DOMAIN_STAGING,
    QUANTIC_DOMAIN_STAGING,
    targetIsWhitelisted,
} from 'PedagoDomainConstants';
import NetworkConnection from 'NetworkConnection';
import Auid from 'Auid';
import setSpecLocales from 'Translation/setSpecLocales';
import { targetBrand, targetBrandConfig } from 'AppBranding';
import { identifyUser } from 'TinyEventLogger';
import { getHasPendingAdmissionOfferOrIsNotYetCurrent } from 'Users';
import { tryToRegisterDeviceForRemoteNotifications } from 'RemoteNotificationHelper';
import unsetCurrentUser from '../../unsetCurrentUser';
import locales from '../../locales/authentication/validation_responder-en.json';
import angularModule from './authentication_module';

setSpecLocales(locales);

angularModule.factory('ValidationResponder', [
    '$injector',
    $injector => {
        const ngDeviseTokenAuthClient = $injector.get('ngDeviseTokenAuthClient');
        const $rootScope = $injector.get('$rootScope');
        const $location = $injector.get('$location');
        const $window = $injector.get('$window');
        const User = $injector.get('User');
        const EventLogger = $injector.get('EventLogger');
        const blockAuthenticatedAccess = $injector.get('blockAuthenticatedAccess');
        const $q = $injector.get('$q');
        const omniauth = $injector.get('omniauth');
        const institutionalSubdomain = $injector.get('institutionalSubdomain');
        const $timeout = $injector.get('$timeout');
        const ClientStorage = $injector.get('ClientStorage');
        const ConfigFactory = $injector.get('ConfigFactory');
        const offlineModeManager = $injector.get('offlineModeManager');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const frontRoyalStore = $injector.get('frontRoyalStore');
        const DialogModal = $injector.get('DialogModal');
        const safeApply = $injector.get('safeApply');
        const TranslationHelper = $injector.get('TranslationHelper');
        const translationHelper = new TranslationHelper('authentication.validation_responder');
        const ErrorLogService = $injector.get('ErrorLogService');

        function redirectToCorrectDomain(user, config) {
            if (
                $window.CORDOVA ||
                (config.isAlternativeStagingEnvironment() && $location.host() !== QUANTIC_DOMAIN_STAGING)
            ) {
                return false;
            }

            // If someone is at an institutional subdomain, do not redirect them.
            if (institutionalSubdomain?.id) {
                return false;
            }

            /* All of this is a bit hackish.  Basically, we want to have a way
                to test this on local and staging.  In other places in the app,
                however, QUANTIC_DOMAIN is always quantic.edu and SMARTLY_DOMAIN
                is always smart.ly regardless of the environment.  So we need
                a workaround just for this particular thing.  Further explanation
                in inline comments below */

            let smartlyHost;
            let quanticHost;
            let targetProtocol = 'https';
            let currentHost;

            // If the webserver is running in development mode,
            // and FORWARD_TO_CORRECT_DOMAIN_IN_DEVMODE is set to true
            // in the configuration,
            // then quantic users will be forwarded to localhost:3001
            // and smartly users will be forwarded to localhost:3000.
            if (config.appEnvType() === 'development') {
                if (config.forward_to_correct_domain_in_devmode !== 'true') {
                    return false;
                }
                quanticHost = 'localhost:3001';
                smartlyHost = 'localhost:3000';
                targetProtocol = 'http';

                // only pay attention to the port in development
                currentHost = `${$location.host()}:${$location.port()}`;
            }
            // In staging and production, we forward to the correct host
            else if (config.appEnvType() === 'staging') {
                quanticHost = QUANTIC_APP_DOMAIN_STAGING;
                smartlyHost = SMARTLY_APP_DOMAIN_STAGING;
                currentHost = $location.host();
            } else {
                quanticHost = QUANTIC_APP_DOMAIN;
                smartlyHost = SMARTLY_APP_DOMAIN;
                currentHost = $location.host();
            }

            // When China users try to sign in from .edu domain, backend controller redirects
            // them to .cn domain. See china_redirect_mixin.rb.
            // Then they should be consistently redirected to .cn domain after validation success.
            // There's no China app domain for staging, use production domain for both envs.
            if (currentHost === QUANTIC_CHINA_APP_DOMAIN || currentHost === QUANTIC_CHINA_DOMAIN) {
                quanticHost = QUANTIC_CHINA_APP_DOMAIN;
            }
            if (currentHost === QUANTIC_CHINA_APP_DOMAIN_STAGING || currentHost === QUANTIC_CHINA_DOMAIN_STAGING) {
                quanticHost = QUANTIC_CHINA_APP_DOMAIN_STAGING;
            }

            const targetHost = user.active_institution?.domain === QUANTIC_DOMAIN ? quanticHost : smartlyHost;

            if (currentHost !== targetHost) {
                const url = new URL(`${targetProtocol}://${targetHost}${$location.url()}`);
                NavigationHelperMixin.loadUrl(url.toString());
                return true;
            }

            return false;
        }

        // NOTE: `handleValidationSuccess` can and does get called multiple times in marketing
        // registration flows. This is because we explicitly broadcast the `auth:validation-success`
        // event in `SignUpFormHelper.submitRegistration`, then redirect to /home in `marketingSignUpForm`
        // which re-initializes `ValidationResponder` and calls `ngDeviseTokenAuthClient.validateUser`
        // (which broadcasts `auth:validation-success` on success).
        function handleValidationSuccess(ev, userResponse) {
            // handle special-case single page oauth implementation
            if ($location.search() && $location.search().oauth_login) {
                $location.search('oauth_login', undefined);
            }

            const user = User.new(userResponse || ngDeviseTokenAuthClient.user);

            // Clear out the referredById from localStorage upon successful authentication so
            // that a single browser with multiple signups doesn't continue to set the referred_by
            if (ClientStorage.getItem('referredById')) {
                ClientStorage.removeItem('referredById');
            }

            let abortLogin;
            let userFromStore;
            let differentCurrentUser;
            let wasLoggedInAsUserFromStore;
            frontRoyalStore
                .getCurrentUser()
                .then(_userFromStore => {
                    userFromStore = _userFromStore;
                    differentCurrentUser = userFromStore && userFromStore.id !== user.id;

                    // lastLoggedInAsUserId adds support for the loginAs flow
                    const lastLoggedInAsUserId = ClientStorage.getItem('lastLoggedInAsUserId');
                    wasLoggedInAsUserFromStore = lastLoggedInAsUserId && lastLoggedInAsUserId === userFromStore?.id;

                    if (differentCurrentUser) {
                        return frontRoyalStore.hasUnflushedData();
                    }

                    return false;
                })
                .then(hasUnflushedData => {
                    if (differentCurrentUser && hasUnflushedData && !wasLoggedInAsUserFromStore) {
                        DialogModal.alert({
                            content: translationHelper.get('you_have_stored_data_for_another_user', {
                                email: userFromStore.email,
                            }),
                            close: () => {
                                $location.url('/sign-in');
                            },
                        });
                        $rootScope.authCallComplete = true;
                        abortLogin = true;
                        return undefined;
                    }

                    ClientStorage.removeItem('lastLoggedInAsUserId');
                    abortLogin = false;

                    if (differentCurrentUser) {
                        return frontRoyalStore.clearUserSpecificTables();
                    }

                    return undefined;
                })
                .then(() => {
                    if (abortLogin) {
                        return null;
                    }

                    // we need to ensure that prior to validation we actually have
                    // a config loaded in order for events to provide servertime info
                    return ConfigFactory.getConfig();
                })
                .then(config => {
                    if (abortLogin) {
                        return;
                    }
                    if (redirectToCorrectDomain(user, config)) {
                        return;
                    }

                    // If an anonymous user just logged in, store the fact that
                    // they did.  This lets us attach the anonymous user's events
                    // to the actual user, or just filter the anonymous user out of
                    // reports about new users.
                    const currentAuid = Auid.get($rootScope.currentUser);
                    const anonymousUserLogin = currentAuid && currentAuid !== user.id;
                    if (anonymousUserLogin) {
                        EventLogger.log('anonymous_user:login', {
                            logged_in_user_id: user.id,
                        });
                        EventLogger.onUserChange(currentAuid);
                    }

                    $rootScope.currentUser = user;
                    $rootScope.authCallComplete = true;

                    // Keep the user's id as their auid.  That way, any events the
                    // user logs from the marketing pages (when they are not
                    // authenticated), will have the correct id.
                    Auid.set(user.id);

                    EventLogger.log('user:login', {}, { log_to_customerio: true });

                    identifyUser($rootScope.currentUser);

                    // Since remote push services can periodically reset/refresh tokens, we need to initialize
                    // the plugin every time a user is validated and re-register for remote notifications.
                    tryToRegisterDeviceForRemoteNotifications($rootScope.currentUser.id);

                    $rootScope.currentUser.setContinueApplicationInMarketingFlag();

                    $rootScope.$broadcast('validation-responder:login-success', ev);
                })
                .finally(() => {
                    // The methods invoked on frontRoyalStore return a native promise
                    safeApply($rootScope);
                });
        }

        function unsetCurrentUserWrapper() {
            unsetCurrentUser($injector);
        }

        function forwardToTargetUrl(skipFallbackToDashboard) {
            const useLastVisitedRoute = $window.CORDOVA || !!ClientStorage.getItem('logged_in_as');

            // redirect to `last_visited_route` if targeting is applicable, otherwise default to URL query-string or OAuth localStorage values if provided
            const target = useLastVisitedRoute
                ? ClientStorage.getItem('last_visited_route')
                : $location.search().target || ClientStorage.getItem('oauth_redirect_target');

            // remove temp vars from client storage
            ClientStorage.removeItem('logged_in_as');
            ClientStorage.removeItem('oauth_redirect_target');

            if (!$window.CORDOVA) {
                ClientStorage.removeItem('last_visited_route');
            }

            if (target === 'NONE') {
                // do nothing.  This is used when a user gets logged out
                // during an active session and signs back in through the sign-in-form
                $location.search('target', null);
            } else if (target && target.slice(0, 4) === 'http') {
                if (targetIsWhitelisted(target)) {
                    // Marketing pages that require authentication (ex. program family application)
                    // might set this to an absolute url if it's a page we want to return
                    // to after login
                    if (target !== $window.location.href) {
                        // eslint-disable-next-line no-use-before-define
                        ValidationResponder.setHref(target);
                    }
                } else {
                    ErrorLogService.notify("Can't forward to a non-whitelisted target URL.", undefined, {
                        level: 'warning',
                        target,
                    });
                    $location.search('target', null);
                }
            } else if (target) {
                $location.url(target);
            } else if (!skipFallbackToDashboard) {
                $rootScope.goHome();
                $location.search({});
            }
        }

        function forwardToTargetUrlIfExists() {
            forwardToTargetUrl(true);
        }

        function checkAndConfirmBrandRedirect(urlConfig, onConfirm) {
            // If a user gets authenticated by signing in or trying to re-register within one brand context, while they are already part of
            // an institution with another brand, notify them that they will redirect.
            if (willRedirectToDifferentBranding(urlConfig)) {
                showBrandingRedirectConfirm(urlConfig, onConfirm);
            } else {
                onConfirm();
            }
        }

        function willRedirectToDifferentBranding(urlConfig) {
            const user = $rootScope.currentUser;
            const authPageBranding = targetBrand(urlConfig.domain);
            const userInstitutionBranding = targetBrand(user);
            const willRedirectBrand = userInstitutionBranding && authPageBranding !== userInstitutionBranding;
            return willRedirectBrand;
        }

        function showBrandingRedirectConfirm(urlConfig, onConfirm) {
            const user = $rootScope.currentUser;
            const authPageBrandConfig = targetBrandConfig(urlConfig.domain);
            const userBrandConfig = targetBrandConfig(user);

            if (!user.relevantCohort?.confirmBrandRedirect) {
                onConfirm();
                return;
            }

            let message;

            if (user.isCurrentOrHasCompletedActiveProgram || getHasPendingAdmissionOfferOrIsNotYetCurrent(user)) {
                message = translationHelper.get('confirm_branding_redirect', {
                    userBrandName: userBrandConfig.brandNameShort,
                });
            } else {
                message = translationHelper.get('confirm_branding_redirect_contact_to_switch_institutions', {
                    userBrandName: userBrandConfig.brandNameShort,
                    authPageBrandName: authPageBrandConfig.brandNameShort,
                    authPageBrandSupportEmail: authPageBrandConfig.emailAddressForUsername('admissions'),
                });
            }

            DialogModal.confirm({
                text: message,
                confirmButtonText: translationHelper.get('continue'),
                confirmCallback: onConfirm,
                hideCancelButton: true,
                buttonContainerClass: 'center',
                confirmButtonClass: 'no-float',
                classes: ['confirm-brand-redirect-modal'],
            });
        }

        function loginWithSaml() {
            // This is hackish, but since this function is called
            // in a callback to an auth:invalid call, we can't
            // login right away.  We need to wait for ngDeviseTokenAuthClient
            // to cleanup the .dfd property.
            if ($injector.get('ngDeviseTokenAuthClient').dfd) {
                $timeout(loginWithSaml, 50);
                return;
            }

            omniauth
                .loginWithProvider(institutionalSubdomain.id)
                .then(forwardToTargetUrl, unsetCurrentUserWrapper)
                .finally(() => {
                    $rootScope.authCallComplete = true;
                });
        }

        const ValidationResponder = {
            initialize(initiallyDisconnected) {
                //-------------------------
                // Login / Resume / Logout
                //-------------------------

                $rootScope.$on('auth:validation-success', handleValidationSuccess);
                $rootScope.$on('auth:login-success', handleValidationSuccess);

                $rootScope.$on('auth:password-reset-confirm-success', () => {
                    $location.url('/settings/profile');
                });

                //-------------------------
                // Logout / Failure
                //-------------------------

                // FIXME: this trigger on auth:validation-error seems unnecessary.  If auth failed,
                // you won't have a currentUser, right?  Haven't tested this extensively though.
                $rootScope.$on('auth:validation-error', unsetCurrentUserWrapper);
                $rootScope.$on('auth:session-expired', unsetCurrentUserWrapper);

                /*
                    If the initial validation fails, then we have special
                    handling when in an institutional subdomain (like jll.smart.ly).
                    In those cases, we immediately forward to saml oauth
                    authentication.
                */
                if (institutionalSubdomain.id) {
                    $rootScope.$on('auth:invalid', loginWithSaml);
                } else {
                    $rootScope.$on('auth:invalid', unsetCurrentUserWrapper);
                }

                // Setup initial state and kick off call
                $rootScope.authCallComplete = false;

                function attemptOfflineAuthentication(err) {
                    if (err.reason !== 'unauthorized') {
                        throw err;
                    }
                    return offlineModeManager.attemptOfflineAuthentication().then(userAttrs => {
                        // If we did not enter offline mode and load
                        // a user from the store, throw the original error
                        if (!userAttrs) {
                            throw err;
                        }
                        $rootScope.currentUser = User.new(userAttrs);
                        $rootScope.authCallComplete = true;
                    });
                }

                if (!blockAuthenticatedAccess.block) {
                    offlineModeManager.canEnterOfflineMode().then(canEnterOfflineMode => {
                        // skip the auth call altogether if we're offline in hybrid mode
                        if ($window.CORDOVA && !NetworkConnection.online && !canEnterOfflineMode) {
                            unsetCurrentUserWrapper();
                            $rootScope.authCallComplete = true;
                        } else {
                            /*
                            We add the forwardToTargetUrlIfExists to handle the
                            omniauth case.  In that case we would have been forwarded
                            back to this page, and the original target query param
                            will still be in the url.  If there is no target, however,
                            we should just stay on the page we're on. To skip this when it's not
                            the omniauth case, provide `non_oauth=1` in the query params
                        */
                            ngDeviseTokenAuthClient
                                .validateUser()
                                .catch(attemptOfflineAuthentication)
                                .catch(err => {
                                    $rootScope.authCallComplete = true;
                                    if (err.reason !== 'unauthorized') {
                                        throw err;
                                    }

                                    // FIXME: something else triggers `goHome` or an equivalent unless we initially redirect
                                    // to routes in `FrontRoyal` module `run`, which we do when we're initially disconnected.
                                    // This seems like a smell that should be unified, but there probably be dragons.
                                    if (initiallyDisconnected) {
                                        $rootScope.goHome();
                                    }
                                })
                                // Note: If handleValidationSuccess triggered the redirectToCorrectDomain logic then this
                                // logic will be problematic due to the way we're using a promise chain here but an event
                                // listener for validation success. We've fixed a similar oddity with signOut (we used to
                                // have a listener on auth:logout-success). I'm not going to refactor this code to fix
                                // validation success because practically
                                // this was only an issue with the hacky DLP redirect.
                                // See https://trello.com/c/EvPeS2hf
                                .then(user => {
                                    if (user && !$location.search().non_oauth) {
                                        forwardToTargetUrlIfExists();
                                    }
                                })
                                .finally(() => {
                                    safeApply($rootScope);
                                });
                        }
                    });
                }
            },

            setHref(href) {
                $window.location.href = href;
            },

            waitForAuthCallComplete() {
                return $q(resolve => {
                    const cancel = $rootScope.$watch('authCallComplete', authCallComplete => {
                        if (authCallComplete) {
                            resolve();
                            cancel();
                        }
                    });
                });
            },

            // see also: edit_lesson_preview_mode_dir / preview_lesson_dir
            initializePreviewMode() {
                const PreviewWindowConfigFactory = $injector.get('ConfigFactory');

                $window.registerSharedPreviewScope = (scope, currentUser, MasterWindowConfigFactory) => {
                    $rootScope.sharedPreviewScope = scope;
                    $rootScope.currentUser = currentUser;
                    $rootScope.authCallComplete = true;

                    // SiteMetadata assumes that the ConfigFactory has loaded up config
                    // before anything else happens.  This is a safe assumption because the config
                    // starts loading in FrontRoyal/angularModuleAngular/index.js before anything else happens, and queuing ensures that
                    // it blocks everything else.  In this case, however, the preview mode window
                    // does not need to make any api requests before rendering the lesson, so it
                    // will blow up trying to render the lesson before the config is loaded.
                    PreviewWindowConfigFactory._config = MasterWindowConfigFactory._config;

                    // Since we're copying config, we need to be sure to copy the initTime as well
                    PreviewWindowConfigFactory.serverTime = MasterWindowConfigFactory.serverTime;

                    safeApply($rootScope);
                };
            },

            handleValidationSuccess,
            unsetCurrentUserWrapper,
            forwardToTargetUrl,
            checkAndConfirmBrandRedirect,
        };

        return ValidationResponder;
    },
]);

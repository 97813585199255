import { createApi } from 'ReduxHelpers';
import { getBackRoyalApiBaseConfig } from 'BackRoyalApi';
import { type AnyObject } from '@Types';

export const autoSuggestOptionsApi = createApi('AutoSuggestOptions', {
    ...getBackRoyalApiBaseConfig('AutoSuggestOptions'),
    endpoints: builder => ({
        autoSuggestOptions: builder.query<unknown, { filters: AnyObject; limit?: number }>({
            query: ({ filters = {}, limit = 10 }) => ({
                url: 'auto_suggest_options.json',
                params: {
                    ...Object.entries(filters).reduce(
                        (acc, [key, value]) => ({ ...acc, [`filters[${key}]`]: value }),
                        {},
                    ),
                    limit,
                },
            }),
            transformResponse: (response: { contents: unknown }) => response.contents,
        }),
    }),
});

export default autoSuggestOptionsApi;

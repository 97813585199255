import { angularInjectorProvider } from 'Injector';

/*
    Note that we do not clear the user out of the store when this method is called.
    This method gets called on various types of authentication failures.  But, even if
    auth fails, you might still have unflushed data in the client database that we do not
    want to remove.  We want to keep that data in the db so that once the user authenticates
    successfully, it can be flushed and saved to the server.
*/
export default function unsetCurrentUser(injector) {
    injector = injector || angularInjectorProvider.injector;
    const $rootScope = injector.get('$rootScope');
    $rootScope.currentUser = undefined;
}

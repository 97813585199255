import angularModule from 'Authentication/angularModule/scripts/authentication_module';
// eslint-disable-next-line import/extensions
import 'marketing/scripts/sm-form';
import { isSameSiteNoneCompatible } from 'should-send-same-site-none';

angularModule.directive('marketingSignUpForm', [
    '$injector',

    /*
            This uses a terrible hybrid of pixelmatters' smForm code and out angular-driven code.

            In many cases we have to duplicate validations and things, so in the html
            you will see pattern and ng-pattern, and you will see ng-models on form elements
            where it seems like it's not being used.

            That is because smForm is generally responsible for showing error messages next
            to form inputs, which angular is responsible for disabling the submit button
            when the form is invalid.

            See docs/user_registration.md for an overview of how information is passed
            through the registration process
        */

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const SignUpFormHelper = $injector.get('SignUpFormHelper');
        const $window = $injector.get('$window');
        const $timeout = $injector.get('$timeout');
        const ClientStorage = $injector.get('ClientStorage');
        const EventLogger = $injector.get('EventLogger');
        const $location = $injector.get('$location');
        const scopeInterval = $injector.get('scopeInterval');
        const safeDigest = $injector.get('safeDigest');
        const ConfigFactory = $injector.get('ConfigFactory');
        const TranslationHelper = $injector.get('TranslationHelper');
        const translationHelper = new TranslationHelper('authentication.sign_up_form');
        const ValidationResponder = $injector.get('ValidationResponder');

        return {
            restrict: 'A',
            link(scope, elem, attrs) {
                scope.$location = $location;
                scope.gdprAppliesToUser = false;
                scope.showAppleProvider = isSameSiteNoneCompatible(navigator.userAgent);

                const isDynamicLandingPage = !!attrs.completeDynamicLandingFormOnRegistration;

                ConfigFactory.getConfig().then(config => {
                    scope.gdprAppliesToUser = config.gdprAppliesToUser();
                });

                function completeDynamicLandingForm(urlConfig) {
                    const onContinueClick = event => {
                        event.preventDefault();
                        const href = event.target?.href;
                        const onConfirm = () => {
                            scope.setHref(href);
                        };
                        ValidationResponder.checkAndConfirmBrandRedirect(urlConfig, onConfirm);
                    };

                    $window.afterSignedUpOnDynamicLandingPage(onContinueClick);

                    // We used to ensureLoginEvent in the handleValidationSuccess ValidationResponder
                    // function, but we noticed that it was getting called multiple times quickly
                    // during OAuth registration because of the redirects. So we moved it here
                    // so that we call it when the app is hopefully at a stable point.
                    $rootScope.currentUser.ensureLoginEvent();
                }

                // We have to use getWithPromise here because we can land on this page before we've
                // finished asynchronously loading non-En locale files into $translate.
                translationHelper.getWithPromise('sending').then(translation => {
                    scope.sending = translation;
                });

                // If we want the user to see the success screen after registration, then
                // after an oauth login we need to bring zer back to this page. See
                // authFormHelperMixin.loginWithProvider
                if (isDynamicLandingPage) {
                    scope.oauthRedirectTarget = $window.location.href;
                }

                // dynamic_landing_page may have programType as an attribute
                attrs.$observe('programType', programType => {
                    if (programType) {
                        scope.programType = programType;
                    }
                });
                // /candidates/signup may have programType in a querypara
                scope.$watch('$location.search().program', programType => {
                    if (programType) {
                        scope.programType = programType;
                    }
                });

                // FIXME: we are ignoring preventSubmit right now because it
                // would interfere with smForm
                const signUpFormHelper = new SignUpFormHelper(scope);

                signUpFormHelper.getConfig(attrs.urlPrefix).then(urlConfig => {
                    // used in ng-disabled bindings, poor-man's polling-bus. force a digest.
                    scope.$window = $window;
                    scopeInterval(
                        scope,
                        () => {
                            safeDigest(scope);
                        },
                        250,
                    );

                    // After logging in, weneed to redirect to the
                    // front-royal app.  We have the front royal js code
                    // loaded here, but not the  html wrapper with the ng-view and
                    // everything
                    signUpFormHelper.onForwardToNextPage(() => {
                        // showSuccessOnRegistration is used on the dynamic_landing_page. In that
                        // case, we want to show the success message on the form before having them
                        // click to go home
                        if (isDynamicLandingPage) {
                            completeDynamicLandingForm(urlConfig);
                        } else {
                            // not on Dynamic Landing Page
                            const onConfirm = () => scope.setHref('/home');
                            ValidationResponder.checkAndConfirmBrandRedirect(urlConfig, onConfirm);
                        }
                    });

                    // separate method for mocking in specs
                    scope.setHref = href => {
                        $window.location.href = href;
                    };

                    function updateErrors() {
                        const errors = scope.form_errors;
                        const message = _.values(errors).join(', ');
                        const invalidFields = _.keys(errors);

                        // We special handle phone and location because they are form elements handled by
                        // angular rather than smForm.  They are different because location-autcomplete does
                        // not seem to integrate with form_errors in the same way.  There is probably some way
                        // to reconcile it, but it seems to work for now.
                        const showPhoneError = !!(
                            errors.phone && elem.find('.sm-form__group--phone-number :focus').length === 0
                        );
                        elem.find('.sm-form__group--phone-number').toggleClass('sm-form__group--error', showPhoneError);

                        const showEmailError = !!(
                            errors.email && elem.find('.sm-form__group.email :focus').length === 0
                        );
                        elem.find('.sm-form__group.email').toggleClass('sm-form__group--error', showEmailError);

                        if (scope.signUpForm.location) {
                            const locationEl = elem.find('.sm-form__group.location');
                            const showLocationError =
                                !scope.signUpForm.location.$valid &&
                                locationEl.find(':focus').length === 0 &&
                                locationEl.find('.ng-touched').length !== 0;
                            locationEl.toggleClass('sm-form__group--error', showLocationError);
                            locationEl
                                .find('.sm-form__error-message')
                                .text(showLocationError ? translationHelper.get('location_error_required') : '');
                        }

                        // After the form is submitted, let smForm handle displaying a message
                        // under the submit button
                        if (scope.signUpForm.$submitted) {
                            scope.smForm.onFormError({
                                message,
                                invalidFields,
                            });
                        }
                    }

                    function initializeForm() {
                        const SmForm = $injector.get('$window').smForm;

                        // because SmForm is loaded in the DOM by another script bundle, we need to handle the possibility it hasn't finished loading yet
                        if (angular.isUndefined(SmForm)) {
                            $timeout(initializeForm, 250);
                            return;
                        }

                        /*
                                In the case where we are configured to show the success message after the form is
                                filled out (on the dynamic_landing_page), we want to show the success message
                                immediately if a logged-in user comes
                                to this page. Note that this also applies to someone using oauth to sign up.  Ze will
                                be returned to this page, and at that point will be a logged-in user and see the success
                                message.

                                In practice, we should only ever observe this event in situations where we are in fact configured
                                to show the success message.  In other cases, like at candidates/signup, the server checks
                                for auth headers and forwards a logged-in user to /home before ze ever gets to this page.

                            */
                        const stopWatchingForLoginSuccess = scope.$on('validation-responder:login-success', () => {
                            if (isDynamicLandingPage) {
                                completeDynamicLandingForm(urlConfig);
                            }
                        });

                        // If a user hits the page with a token, we show the success message.  However, if that
                        // token turns out to be invalid, then we remove the success message.
                        scope.$on('auth:validation-error', () => {
                            // HACK: We check for hideSuccess existing because there's a race condition where this code could
                            // fire before hideSuccess is defined. The DTA switchover really brought it out because we always
                            // initially fire auth:validation-error, since the client now has to ask the server if the user is
                            // authenticated (rather than relying on localStorage). The worst that could happen is a user who has
                            // an auth cookie that's not valid will see the success message on the DLP (since we only show the
                            // success message when the user window.hasAuthCookie). But that's so unlikely to happen given our
                            // ten year expiry. And we're soon getting rid of this code anyway when the DLP moves to Gatsby.
                            if (isDynamicLandingPage && window.hideSuccess) {
                                window.hideSuccess();
                            }
                        });

                        scope.smForm = new SmForm({
                            selector: '#signup-form',
                            submitCallback(registrationInfo) {
                                // After this point, we leave the registration implementations to
                                // worry about watching for login success
                                stopWatchingForLoginSuccess();

                                [
                                    'phone',
                                    'place_details',
                                    'place_id',
                                    'professional_organization',
                                    'provider',
                                    'email', // shouldn't need this one, but smForm is gonna filter it out if the input is disabled
                                ].forEach(prop => {
                                    if (scope[prop]) {
                                        registrationInfo[prop] = scope[prop];
                                    }
                                });

                                if (scope.programType) {
                                    registrationInfo.program_type = scope.programType; // used on dynamic_landing_page
                                }
                                signUpFormHelper.submitRegistration(registrationInfo);
                            },

                            // special additions we made to smForm to support hybrid
                            // angular-smForm forms.
                            disableSubmitHandling: true,
                        });

                        scope.$watchCollection('form_errors', updateErrors);

                        // I guess this is necessary because it takes a little while before the
                        // value actually updates.  Only an issue when you select by clicking a
                        // location with the cursor.  Not an issue if you use arrow keys and the
                        // enter button.
                        scope.$watch('signUpForm.location.$valid', updateErrors);
                        elem.find('input').on('blur', updateErrors);

                        scope.email = scope.email || ClientStorage.getItem('prefilledEmail') || '';
                        ClientStorage.removeItem('prefilledEmail');

                        // log the prefilled value for data analysis later
                        if (scope.email) {
                            EventLogger.log(
                                'sign_up:pre_entered_email',
                                {
                                    label: 'sign_up:pre_entered_email',
                                    email: scope.email,
                                },
                                {
                                    segmentioType: 'sign_up:pre_entered_email',
                                    segmentioLabel: 'sign_up:pre_entered_email',
                                },
                            );
                        }
                    }
                    $timeout().then(initializeForm);

                    // We need to force a page reload when the url changes, since the
                    // stuff here is rendered by rails
                    scope.$on('$locationChangeStart', (_evt, next, prev) => {
                        const locationChanging = prev !== next;

                        // When we login on this page, ngDeviseTokenAuthClient will try to remove the
                        // oauth query params (i.e. auth_token) by calling $location.url.
                        // In that case, we do not want to force a page reload.
                        // const baseChanging = prev.split('?')[0] !== next.split('?')[0];
                        // const ngTokenAuthRemovingQueryParams = prev.match(/\/start/) && !baseChanging;

                        if (prev && locationChanging /* && !ngTokenAuthRemovingQueryParams */) {
                            $window.location.href = next;
                        }
                    });

                    if ($location.search().fixedEmail) {
                        scope.email = $location.search().fixedEmail;
                        scope.disableEmailInput = true;
                    }

                    if ($location.search().fixedCompanyName) {
                        scope.fixedCompanyName = $location.search().fixedCompanyName;
                    }

                    if ($location.search().error) {
                        elem.find('.onboarding__social-auth .sm-form__group').addClass('sm-form__group--error');
                        const errorText = _.chain([$location.search().error])
                            .flattenDeep()
                            .uniq()
                            .values()
                            .join(', ')
                            .value();
                        elem.find('.onboarding__social-auth .sm-form__group .sm-form__error-message').text(errorText);
                    }
                });
            },
        };
    },
]);

import angularModule from 'Authentication/angularModule/scripts/authentication_module';

angularModule.directive('captchaWrapper', [
    '$injector',

    function factory($injector) {
        const $compile = $injector.get('$compile');
        const $window = $injector.get('$window');
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            restrict: 'E',
            scope: {},
            link(scope, elem) {
                // This no longer hinges on whether or not the captcha is
                // required. We are now making an assumption that if you
                // are making use of the captcha-wrapper directive somewhere,
                // you most likely  want that captcha to be required. If it
                // isn't required, this directive likely isn't being used.
                $window.captchaInvalid = () => !window.captchaComplete;

                ConfigFactory.getConfig().then(config => {
                    scope.recaptchaKey = config.recaptcha_site_key;
                    scope.hcaptchaKey = config.hcaptcha_site_key;
                    scope.chinaRegionMode = config.chinaRegionMode();

                    // We embed this HTML here for several reasons:
                    //
                    // 1. We don't use recaptcha in Cordova.
                    // 2. Attemping to exclude these elements from the DOM
                    //    using an ng-if introduces scoping issues that
                    //    impact the usability of the recaptcha.
                    // 3. Attempting to hide these elements in the DOM
                    //    using ng-show/ng-hide means that the recaptcha
                    //    script will be loaded (unsuccessfully) in Cordova.
                    if (scope.hcaptchaKey || scope.recaptchaKey) {
                        let captchaKey;
                        let className;
                        let srcUri;

                        if (scope.chinaRegionMode) {
                            captchaKey = scope.hcaptchaKey;
                            className = 'h-captcha';
                            srcUri = 'https://hcaptcha.com/1/api.js';
                        } else {
                            captchaKey = scope.recaptchaKey;
                            className = 'g-recaptcha';
                            srcUri = 'https://www.google.com/recaptcha/api.js';
                        }

                        elem.append(
                            $compile(
                                '' +
                                    '<div name="captcha_container">' +
                                    ' <script>' +
                                    '   window.captchaComplete = false;' +
                                    '   function captchaCallback() {' +
                                    '     window.captchaComplete = true;' +
                                    '   }' +
                                    ' </script>' +
                                    ` <div class="${className}" ng-attr-data-sitekey="${captchaKey}" data-callback="captchaCallback"></div>` +
                                    ` <script  src="${srcUri}" async defer></script>` +
                                    '</div>',
                            )(scope),
                        );
                    }
                });
            },
        };
    },
]);

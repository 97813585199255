import { setupBrandNameProperties } from 'AppBranding';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { isSameSiteNoneCompatible } from 'should-send-same-site-none';
import { isAndroidDevice } from 'userAgentHelper';

import signupMichaelHorn from 'images/signup/michael_horn.png';
import signupStefanosLoukakos from 'images/signup/stefanos_loukakos.jpg';
import signupDevicesJoinForm from 'images/signup/devices_join_form.png';
import signupBulbmanJoinForm from 'images/signup/bulbman_join_form.png';
import signupFormFieldTemplate from '../views/sign_up_form_field.html';
import signUpSideBarsTemplate from '../views/sign_up_sidebars.html';
import template from '../views/sign_up_form.html';
import angularModule from './authentication_module';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('signUpForm', [
    '$injector',

    function factory($injector) {
        const ClientStorage = $injector.get('ClientStorage');
        const scopeTimeout = $injector.get('scopeTimeout');
        const TranslationHelper = $injector.get('TranslationHelper');
        const SignUpFormHelper = $injector.get('SignUpFormHelper');
        const ValidationResponder = $injector.get('ValidationResponder');
        const EventLogger = $injector.get('EventLogger');
        const $window = $injector.get('$window');
        const safeDigest = $injector.get('safeDigest');
        const ConfigFactory = $injector.get('ConfigFactory');
        const LocalizedCountries = $injector.get('LocalizedCountries');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                urlPrefix: '<',
                onSubmit: '<?',
            },
            link(scope, elem) {
                const config = ConfigFactory.getSync();

                scope.signupMichaelHorn = signupMichaelHorn;
                scope.signupStefanosLoukakos = signupStefanosLoukakos;
                scope.signupDevicesJoinForm = signupDevicesJoinForm;
                scope.signupBulbmanJoinForm = signupBulbmanJoinForm;
                scope.signupFormFieldTemplate = signupFormFieldTemplate;
                scope.signUpSideBarsTemplate = signUpSideBarsTemplate;

                // Show Apple as an Oauth provider in every browser compatible with the SameSite=None cookie attribute
                // unless we're in the Android app (we do show it in mobile web on Android).
                scope.showAppleProvider =
                    isSameSiteNoneCompatible(navigator.userAgent) && !($window.CORDOVA && isAndroidDevice());

                setupBrandNameProperties($injector, scope, { config });
                scope.showPrivacyNotice = scope.showPrivacyNotice || config.gdprAppliesToUser();
                scope.chinaRegionMode = config.chinaRegionMode();

                $injector.get('scrollHelper').scrollToTop();

                const translationHelper = new TranslationHelper('authentication.sign_up_form');

                scope.form = {};
                scope.includedFieldsSubmitType = 'submit';

                const yearOptions = [];
                for (let j = new Date().getUTCFullYear(); j >= 1940; j--) {
                    yearOptions.push({
                        label: String(j),
                        value: j,
                    });
                }

                const fields = {
                    name: {
                        type: 'text',
                        name: 'name',
                        placeholder: 'default_placeholder',
                    },
                    company: {
                        type: 'company',
                        name: 'professional_organization',
                        placeholder: 'default_placeholder',
                    },
                    job_title: {
                        type: 'text',
                        name: 'job_title',
                        placeholder: 'default_placeholder',
                    },
                    phone: {
                        type: 'tel',
                        name: 'phone',
                        placeholder: 'default_placeholder',
                    },
                    country: {
                        type: 'selectize',
                        name: 'country',
                        placeholder: 'country',
                        selectizeConfig: {
                            maxItems: 1,

                            // We have to turn off 'sortField' here because selectize does not support
                            // Chinese. See comment near `Selectize.defaults.sortField in
                            // FrontRoyal/angularModuleAngular/index.js
                            //
                            // The countries passed in here will be sorted by LocalizedCountries
                            sortField: '$order',
                        },
                        options: LocalizedCountries.getForCurrentLocale(),
                    },
                    email: {
                        type: 'email',
                        name: 'email',
                        placeholder: 'default_placeholder',
                    },
                    password: {
                        type: 'password',
                        name: 'password',
                        placeholder: 'default_placeholder',
                    },
                    location: {
                        type: 'location',
                        name: 'location',
                        placeholder: 'default_placeholder',
                    },
                };

                function handleReCaptchaFields() {
                    // Handle instances where we want to toggle off server-side ReCaptcha support
                    // We do this because we don't want an unexpected UX. We have all the support
                    // needed below, but in this case, we pass a known value
                    scope.showCaptcha = false;

                    // see also: `CustomRegistrationsController` where we disable the captcha on
                    // Cordova clients.
                    scope.form.cordova = !!$window.CORDOVA;

                    if (!$window.CORDOVA && !$window.RUNNING_IN_TEST_MODE) {
                        scope.showCaptcha = true;

                        // used in ng-disabled bindings, poor-man's polling-bus. force a digest.
                        const scopeInterval = $injector.get('scopeInterval');
                        scope.$window = $window;
                        scopeInterval(
                            scope,
                            () => {
                                safeDigest(scope);
                            },
                            250,
                        );
                    }
                }

                const signUpFormHelper = new SignUpFormHelper(scope);

                // setup attributes using config and urlPrefix, if any
                signUpFormHelper.getConfig(scope.urlPrefix).then(urlConfig => {
                    scope.domain = urlConfig.domain;
                    scope.orLocaleKey = urlConfig.alternate_form_locales
                        ? translationHelper.get('or')
                        : translationHelper.get('or_enter_info_below');

                    if (urlConfig.show_context_sidebar) {
                        scope.contextSidebarLocales = {
                            sign_up: translationHelper.get('sign_up_for_free'),
                            bullets: urlConfig.sidebar_bullet_locale_keys.map(localeKey =>
                                translationHelper.get(localeKey),
                            ),
                        };
                    }

                    scope.showSignUpSideBars = urlConfig.showSignUpSideBars;
                    scope.headerMessage = urlConfig.sign_up_message;
                    scope.submitLocaleKey = urlConfig.submit_button_text;
                    scope.requireCompanyEmail = urlConfig.require_company_email;
                    scope.showPrivacyNotice = scope.showPrivacyNotice || urlConfig.show_privacy_notice;

                    handleReCaptchaFields();

                    const includedFieldIds = urlConfig.included_field_ids;

                    angular.merge(fields, urlConfig.field_overrides || {});

                    scope.showSocial = !urlConfig.disable_oauth;

                    scope.includedFields = _.map(includedFieldIds, id => fields[id]);

                    // see vendor/front-royal/marketing/scripts/events.js#saveEmail
                    if (_.includes(includedFieldIds, 'email')) {
                        scope.form.email = ClientStorage.getItem('prefilledEmail') || '';
                        ClientStorage.removeItem('prefilledEmail');

                        // log the prefilled value for data analysis later
                        if (scope.form.email) {
                            EventLogger.log(
                                'sign_up:pre_entered_email',
                                {
                                    label: 'sign_up:pre_entered_email',
                                    email: scope.form.email,
                                },
                                {
                                    segmentioType: 'sign_up:pre_entered_email',
                                    segmentioLabel: 'sign_up:pre_entered_email',
                                },
                            );
                        }
                    }

                    scope.termsKey = urlConfig.terms_key;
                    scope.emailPlaceholder = urlConfig.email_placeholder || '';

                    signUpFormHelper.onForwardToNextPage(() => {
                        // From the onboarding sign-up we pass a callback that takes the user
                        // to a next step where he or she can optionally complete the MBA application
                        if (scope.onSubmit) {
                            scope.onSubmit();
                            return;
                        }

                        const onConfirm = () => ValidationResponder.forwardToTargetUrl();
                        ValidationResponder.checkAndConfirmBrandRedirect(urlConfig, onConfirm);
                    });
                });

                scope.submitRegistration = () => {
                    const captchaResponse = elem.find('[name="g-recaptcha-response"]').val();
                    if (captchaResponse) {
                        scope.form['g-recaptcha-response'] = captchaResponse;
                    }
                    signUpFormHelper.submitRegistration(scope.form);
                };

                scope.updatePasswordErrors = name => {
                    // HACK: this logic depends on CSS classes being set, which won't happen until after a digest cycle
                    scopeTimeout(
                        scope,
                        () => {
                            // if client side validation thinks password is invalid, display message
                            const passwordInput = $(`input[name="${name}"]`);
                            if (passwordInput && passwordInput.attr('class')) {
                                // if the form is invalid and the user has actually typed something, display errors if any
                                if (
                                    passwordInput.attr('class').includes('ng-invalid') &&
                                    passwordInput.attr('class').indexOf('ng-pristine') !== 0
                                ) {
                                    scope.form_errors[name] = translationHelper.get('password_min_length');
                                } else {
                                    scope.form_errors[name] = undefined;
                                }
                            }
                        },
                        10,
                    );
                };

                scope.toggleShowPassword = evt => {
                    evt.preventDefault();
                    evt.stopImmediatePropagation();
                    scope.showPassword = !scope.showPassword;
                };
            },
        };
    },
]);

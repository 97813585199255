
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.authentication = window.Smartly.locales.modules.en.back_royal.authentication || {};
window.Smartly.locales.modules.en.back_royal.authentication.validation_responder = window.Smartly.locales.modules.en.back_royal.authentication.validation_responder || {};
window.Smartly.locales.modules.en.back_royal.authentication.validation_responder = {...window.Smartly.locales.modules.en.back_royal.authentication.validation_responder, ...{
    "you_have_stored_data_for_another_user": "You have stored data for {{email}}.  Please login as that user in order to save your data to the server.",
    "confirm_branding_redirect": "As a {{userBrandName}} user, you will now be directed to your {{userBrandName}} account.",
    "confirm_branding_redirect_contact_to_switch_institutions": "As a {{userBrandName}} user, you will now be directed to your {{userBrandName}} account. If you'd like to switch to {{authPageBrandName}}, please contact <a href=\"mailto:{{authPageBrandSupportEmail}}\" target=\"_blank\">{{authPageBrandSupportEmail}}</a>.",
    "continue": "Continue"
}
}
    
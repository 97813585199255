import angularModule from 'Authentication/angularModule/scripts/authentication_module';
import { setupBrandNameProperties, setupBrandScopeProperties } from 'AppBranding';
import template from 'Authentication/angularModule/views/complete_registration.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('completeRegistration', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                NavigationHelperMixin.onLink(scope);
                setupBrandNameProperties($injector, scope);
                setupBrandScopeProperties($injector, scope, ['completeRegistrationLogo']);
            },
        };
    },
]);


        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.authentication = window.Smartly.locales.modules.en.back_royal.authentication || {};
window.Smartly.locales.modules.en.back_royal.authentication.sign_in = window.Smartly.locales.modules.en.back_royal.authentication.sign_in || {};
window.Smartly.locales.modules.en.back_royal.authentication.sign_in = {...window.Smartly.locales.modules.en.back_royal.authentication.sign_in, ...{
    "do_not_have_an_account": "Don't have an account?",
    "login": "Login to <strong>{{brandName}}</strong>",
    "sign_up": "Sign Up",
    "sign_up_to_your_account": "Sign Up to your {{brandName}} account",
    "login_to_miyamiya": "Login to <strong>Miya Miya</strong>",
    "login_to_valar": "Login to <strong>Valar</strong>",
    "already_have_an_account": "Already have an account?",
    "log_in": "Log In",
    "icp_file_number": "Shanghai ICP No. 2021026217-2",
    "security_filing_number": "Shanghai Public Network Security No. 31010602006704"
}
}
    
import angularModule from 'Authentication/angularModule/scripts/authentication_module';
import { setupBrandNameProperties } from 'AppBranding';
import template from 'Authentication/angularModule/views/institution_register.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('institutionRegister', [
    '$injector',

    function factory($injector) {
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const JoinConfig = $injector.get('JoinConfig');
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                urlPrefix: '@',
            },
            link(scope) {
                NavigationHelperMixin.onLink(scope);
                setupBrandNameProperties($injector, scope, { config: ConfigFactory.getSync() });

                JoinConfig.getConfig(scope.urlPrefix).then(urlConfig => {
                    scope.urlConfig = urlConfig;
                });
            },
        };
    },
]);

import angularModule from 'Authentication/angularModule/scripts/authentication_module';
import { setupBrandNameProperties } from 'AppBranding';
import template from 'Authentication/angularModule/views/sign_in.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import logoSecurityFilingNumber from 'images/logo-security-filing-number.png';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('signIn', [
    '$injector',

    function factory($injector) {
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const JoinConfig = $injector.get('JoinConfig');
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                urlPrefix: '@',
            },
            link(scope) {
                $injector.get('scrollHelper').scrollToTop();
                const config = ConfigFactory.getSync();

                scope.logoSecurityFilingNumber = logoSecurityFilingNumber;
                scope.chinaRegionMode = config.chinaRegionMode();

                NavigationHelperMixin.onLink(scope);
                setupBrandNameProperties($injector, scope, { config });

                scope.joinLinkClicked = () => {
                    scope.gotoJoin();
                };

                JoinConfig.getConfig(scope.urlPrefix).then(urlConfig => {
                    scope.urlConfig = urlConfig;
                });
            },
        };
    },
]);


        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.authentication = window.Smartly.locales.modules.en.back_royal.authentication || {};
window.Smartly.locales.modules.en.back_royal.authentication.institution_register = window.Smartly.locales.modules.en.back_royal.authentication.institution_register || {};
window.Smartly.locales.modules.en.back_royal.authentication.institution_register = {...window.Smartly.locales.modules.en.back_royal.authentication.institution_register, ...{
    "already_have_an_account": "Already have an account? <a ng-click='gotoSignIn(false)'>Login</a>",
    "next": "Next",
    "sign_up": "Sign up for <strong>{{brandName}}</strong>",
    "free_demo": "{{brandName}} Free Demo",
    "subtitle_math101a": "Foundations of Math: Math 101A<br>Professor Daniel Mintz<br>Tuesday 1pm<br>Fall 2016",
    "subtitle_math101b": "Foundations of Math: Math 101B<br>Professor Daniel Mintz<br>Wednesday 7pm<br>Fall 2016",
    "subtitle_iebusiness": "For IE International MBA students",
    "subtitle_research_study17": "Please create a new account using the email address you used to sign up for the study. You may choose any password.",
    "subtitle_georgetownmim": "Georgetown Master's in Management Program"
}
}
    
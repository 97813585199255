import { angular2react } from 'angular2react';
import { type auto, type IComponentOptions } from 'angular';
import { type AnyObject } from '@Types';
// https://github.com/bcherny/angular2react-demos/blob/master/multi-file/src/lazyInjector.js

/**
 * Angular2react needs an $injector, but it doesn't actually invoke $injector.get
 * until we invoke ReactDOM.render. We can take advantage of this to provide the
 * "lazy" injector below to React components created with angular2react, as a way
 * to avoid component ordering issues.
 *
 * @see https://github.com/coatue-oss/angular2react/issues/12
 */

// state

function getLazyInjector() {
    let $injector: auto.IInjectorService;

    const lazyInjector = {
        get $injector() {
            return {
                get get() {
                    return $injector.get;
                },
            };
        },
        set $injector(_$injector) {
            $injector = _$injector as auto.IInjectorService;
        },
    };
    return lazyInjector;
}

export function angularDirectiveToReact<T extends AnyObject = AnyObject>(
    moduleName: string,
    directiveName: string,
    directiveDefinitionOrBindings: AnyObject,
) {
    const bindings = directiveDefinitionOrBindings.scope || directiveDefinitionOrBindings;
    const mockComponent = {
        bindings,
    } as IComponentOptions;
    const lazyInjector = getLazyInjector();

    angular.module(moduleName).run([
        '$injector',
        (_$injector: auto.IInjectorService) => {
            lazyInjector.$injector = _$injector;
        },
    ]);

    return angular2react<T>(directiveName, mockComponent, lazyInjector.$injector as auto.IInjectorService);
}

export default angularDirectiveToReact;
